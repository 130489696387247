import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
    TabContainer,
    VideoContainer,
    TextContainer
} from './tabs.styles'

import './tabs.scss'

const FourthTab = () => {
    return(
        <TabContainer>
            <VideoContainer>
                <StaticImage
                    className="tab-img"
                    src='../../../images/homepage/NewMetrics.png'
                    alt='product-analytics'
                    loading='eager'
                    placeholder='none'
                />
            </VideoContainer>

            <TextContainer>
                <p>Get in-depth insights in how your interactive videos perform. See real time analytics with a break down of exactly how your audience engage and convert from your videos. Adjust your experience and start seeing the impact of your changes immediately.</p>
            </TextContainer>
        </TabContainer>
    )
}

export default FourthTab