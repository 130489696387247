import styled from "styled-components";

export const TabContainer = styled.div`
    padding: 30px 0;
    overflow: hidden;

    @media screen and (min-width: 900px) {
        display: flex;
    }
`
export const TabVideo = styled.video`
    max-width: 100%;
    margin-bottom: 30px;
    border-radius: 5px;
`
export const VideoContainer = styled.div`
    margin-bottom: 30px;

    @media screen and (min-width: 900px) {
        min-width: 50%;
        max-width: 50%;
        margin-right: 30px;
        margin-bottom: 0;
    }
`
export const TextContainer = styled.div`
    p {
        line-height: 2rem;
    }
    @media screen and (min-width: 900px) {
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 25px;
    }
`
export const QiwioVideo = styled.iframe`
    border: none;
    min-width: 100%;
    height: 100%;
    border-radius: 5px;
    aspect-ratio: 16 / 9;

    @media screen and (max-width: 900px) {
        min-height: 190px;
        aspect-ratio: 16 / 9;
        max-width: 90vw;
        border-radius: 5px;
    }
`