import React from "react";

import {
    TabContainer,
    VideoContainer,
    TextContainer,
    QiwioVideo
} from './tabs.styles'


const FirstTab = () => {

    return(
        <TabContainer>
            <VideoContainer>
                <QiwioVideo src="https://player.qiwio.io/inZgAziCZvtdYoijVFJQpA?mode=embed&auto=true" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                {/*
                <TabVideo autoPlay muted loop>
                    <source src="https://res.cloudinary.com/qiwio/video/upload/q_auto,f_auto/v1654163612/Hotspot-feature.mp4" type="video/mp4"/>
                </TabVideo>
                */}
            </VideoContainer>

            <TextContainer>
                <p>Add interactive hotspots to your videos and then link those to several different actions such as product overlays, product pages and more! Raise conversions by allowing your viewers to explore the products in your videos.</p>
            </TextContainer>
        </TabContainer>
    )
}

export default FirstTab