import React, { useState } from 'react';

import './tabs.scss'

import FirstTab from './tab-content/first-tab.component';
import SecondTab from './tab-content/second-tab.component';
import ThirdTab from './tab-content/third-tab.component';
import FourthTab from './tab-content/fourth-tab.component';

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('tab1')

    const handleTab1 = () => {
        // update the state to tab1
        setActiveTab('tab1')
    }

    const handleTab2 = () => {
    // update the state to tab2
    setActiveTab('tab2')
    }

    const handleTab3 = () => {
    // update the state to tab3
    setActiveTab('tab3')
    }

    const handleTab4 = () => {
    // update the state to tab3
    setActiveTab('tab4')
    }

    const renderOutlet = () => {
        if(activeTab === 'tab1') {
            return <FirstTab />
        } else if (activeTab === 'tab2') {
            return <SecondTab />
        } else if (activeTab === 'tab3') {
            return <ThirdTab />
        } else {
            return <FourthTab />
        }
    }

    return (
        <div className='Tabs'>
            <ul className='nav'>
                <li
                    className={activeTab === 'tab1' ? 'active' : ''}
                    onClick={handleTab1}
                >
                    Hotspots
                </li>
                <li
                    className={activeTab === 'tab2' ? 'active' : ''}
                    onClick={handleTab2}
                >
                    Video funnels
                </li>
                <li
                    className={activeTab === 'tab3' ? 'active' : ''}
                    onClick={handleTab3}
                >
                    Interactive video editor
                </li>
                <li
                    className={activeTab === 'tab4' ? 'active' : ''}
                    onClick={handleTab4}
                >
                    Metrics
                </li>
            </ul>
            <div className="outlet">
                {renderOutlet()}
            {/* {activeTab === 'tab1' ? <FirstTab /> : <SecondTab />}*/}
            </div>
        </div>
    )
}

export default Tabs
