import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
    TabContainer,
    VideoContainer,
    TextContainer
} from './tabs.styles'

import './tabs.scss'

const ThirdTab = () => {
    return(
        <TabContainer>
            <VideoContainer>
                <StaticImage
                    className="tab-img"
                    src='../../../images/homepage/InteractiveVideoGraphic2.png'
                    alt='product-analytics'
                    loading='eager'
                    placeholder='none'
                />
            </VideoContainer>

            <TextContainer>
                <p>Using Qiwio’s interactive video editor, you can drag and drop interactive elements on top of your videos to engage your viewers. These range from simple text or button elements to hotspots and product overlays, the possibilities are endless! Turn your traditional videos into engaging experiences with our intuitive drag and drop editor.</p>
            </TextContainer>
        </TabContainer>
    )
}

export default ThirdTab