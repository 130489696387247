import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel" // unopinionated react slider library
import "pure-react-carousel/dist/react-carousel.es.css" // Basic css for slider component

import Layout from "../components/theme/layout"
import Seo from "../components/seo"

import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"

import Tabs from "../components/tab-component/tabs.component"
import CtaSection from "../components/cta-section/cta-section.component"
import NewsletterSection from "../components/newsletter/newsletter.component"

import "./index.scss"

// Rendering page
export default function Home() {
  return (
    <Layout>
      <Seo title="Interactive shopping platform" />
      <HeroContainer>
        <Heading1>
          Interactive shopping platform for modern eCommerce brands
        </Heading1>
        <Heading4>
          Increase engagement and conversions with interactive 'shoppable'
          videos & photos
        </Heading4>

        <BtnContainer>
          <PrimaryBtn to="/free-trial">Try Qiwio for free</PrimaryBtn>
        </BtnContainer>

        <ImgContainer className="hero-img-container">
          <HeroVideoContainer>
            <QiwioVideo
              src="https://player.qiwio.io/a3W0dLHs0JAHjydpML3tuw?mode=embed&auto=true"
              frameborder="0"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
              allowFullScreen
            ></QiwioVideo>
          </HeroVideoContainer>
        </ImgContainer>

        <CustomerLogosWrapper>
          <h3>
            Join the leading eCommerce brands re-imagining the shopping
            experience
          </h3>
          <CustomerLogosContainer>
            <CustomerLogo>
              <a
                href="https://www.ajprodukter.se/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  className="customer-logo"
                  src="../images/logos/AJ-logo.svg"
                  alt="AJ Produkter logo"
                  loading="eager"
                  placeholder="none"
                />
              </a>
            </CustomerLogo>
            <CustomerLogo>
              <a href="https://blugiallo.com/" target="_blank" rel="noreferrer">
                <StaticImage
                  className="customer-logo"
                  src="../images/logos/bg_logo.png"
                  alt="Blugiallo logo"
                  loading="eager"
                  placeholder="none"
                />
              </a>
            </CustomerLogo>
            <CustomerLogo>
              <a
                href="https://www.lagerhaus.se/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  className="customer-logo"
                  src="../images/logos/Lagerhaus-logo-white.png"
                  alt="Lagerhaus logo"
                  loading="eager"
                  placeholder="none"
                />
              </a>
            </CustomerLogo>
            <CustomerLogo>
              <a href="https://tenson.com/" target="_blank" rel="noreferrer">
                <StaticImage
                  className="customer-logo"
                  src="../images/logos/Tenson Logo White.png"
                  alt="Tenson AB logo"
                  loading="eager"
                  placeholder="none"
                />
              </a>
            </CustomerLogo>
          </CustomerLogosContainer>
        </CustomerLogosWrapper>
      </HeroContainer>

      <HomepageSection noPadding>
        <CustomerCasesHeader>
          <h4>Customer cases</h4>
          <Link to="/">See more examples</Link>
          <h3>See what your competitors are doing</h3>
        </CustomerCasesHeader>

        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={93}
          totalSlides={1.6}
          step={0.7}
          dragStep={0.5}
          className="carousel-provider"
        >
          <ButtonBack className="carousel-nav-btn">
            <FaChevronLeft />
          </ButtonBack>
          <ButtonNext className="carousel-nav-btn">
            <FaChevronRight />
          </ButtonNext>
          <Slider className="slider">
            <Slide index={0} className="slider-slide">
              <Link to="/case-studies/aj-produkter">
                <UseCaseSliderItemWrapper>
                  <StaticImage
                    style={{
                      gridArea: "1/1",
                    }}
                    className="slider-slide-img"
                    layout="fullWidth"
                    alt="AJ Produkter office"
                    src={"../images/customers/aj produkter/Office 16.6.jpeg"}
                  />
                  <UseCaseSliderItemOverlay>
                    <h3>
                      AJ Produkter created interactive 'shoppable' product
                      guides for a smoother digital shopping experience
                    </h3>
                    <p>AJ Produkter</p>
                  </UseCaseSliderItemOverlay>
                </UseCaseSliderItemWrapper>
              </Link>
            </Slide>
            <Slide index={1} className="slider-slide">
              <Link to="/case-studies/tenson">
                <UseCaseSliderItemWrapper>
                  <StaticImage
                    style={{
                      gridArea: "1/1",
                    }}
                    className="slider-slide-img tenson-slide-mobile"
                    layout="fullWidth"
                    alt="AJ Produkter office"
                    src={
                      "../images/customers/tenson/yokun-rain-jacket-w-cropped.jpg"
                    }
                  />
                  <StaticImage
                    style={{
                      gridArea: "1/1",
                    }}
                    className="slider-slide-img tenson-slide-desktop"
                    layout="fullWidth"
                    alt="AJ Produkter office"
                    src={
                      "../images/customers/tenson/yokun-rain-jacket-w-800px.jpg"
                    }
                  />
                  <UseCaseSliderItemOverlay>
                    <h3>
                      Tenson achieved a 70% engagement rate with interactive
                      shopping
                    </h3>
                    <p>Tenson</p>
                  </UseCaseSliderItemOverlay>
                </UseCaseSliderItemWrapper>
              </Link>
            </Slide>
          </Slider>
        </CarouselProvider>
      </HomepageSection>

      {/*
      <HomepageSection>
        <h4>Customer cases</h4>
        <h3>See what your competitors are doing</h3>

        <UseCaseWrapper>
            <SquareUSeCaseItem>
                <QiwioVideoContainer>
                    <SquareQiwioVideo src="https://player.qiwio.io/mEOaeVpULnBwxKF6czp9sg?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></SquareQiwioVideo>
                </QiwioVideoContainer>
                <UseCaseItemHeader>
                    <h5>Tenson</h5>
                    <h5>Fashion</h5>
                </UseCaseItemHeader>
                <div>
                    <p>Tenson utilizes Qiwio's interactive shopping technology to create a better shopping experience for their website visitors. Throughout their eCommerce site, they add additional product information and product links to better engage their customers and guide them to the right product. First-phase results indicate Qiwio's solutions have contributed to Tenson's e-commerce customers spending more time on site, increased engagement and increase website conversions.</p>
                    <a href="https://tenson.com/" target="_blank" rel="noreferrer">Experience their shopping experience <HiOutlineArrowNarrowRight /></a>
                </div>
            </SquareUSeCaseItem>
            <USeCaseItem>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/g5w-Q5GnBAA?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <UseCaseItemHeader>
                    <h5>AJ Produkter</h5>
                    <h5>Retail office equipment</h5>
                </UseCaseItemHeader>
                <div>
                    <p>With over 30,000 products selling to 14 countries, finding the right product for the right customer can be a challenge. AJ produkter now utilises Qiwio interactive technology in their eCommerce platform to add product information and links within visual content to make their photos and videos truly shoppable.</p>
                    <a href="https://www.ajprodukter.se/kampanjer/personlig-service-ett-samtal-bort/losningar-for-en-battre-skolmiljo" target="_blank" rel="noreferrer">View on AJ Produkter's site <HiOutlineArrowNarrowRight /></a>
                </div>
            </USeCaseItem>
            <USeCaseItem>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/DEhEzOwNj6U?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <UseCaseItemHeader>
                    <h5>Tenson</h5>
                    <h5>Fashion</h5>
                </UseCaseItemHeader>
                <div>
                    <p>Tenson merges their inspirational product imagery with simple, but effective interactive features using Qiwio. By adding hotspots to their product images, customers can find more information very quickly and easily navigate to the correct products that they are interested in.</p>
                    <a href="https://tenson.com/en-se/start" target="_blank" rel="noreferrer">View on Tenson's site <HiOutlineArrowNarrowRight /></a>
                </div>
            </USeCaseItem>
            <USeCaseItem>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/e5c5jn1C04iVNuk9ZCi2Jw?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <UseCaseItemHeader>
                    <h5>Tenson</h5>
                    <h5>Fashion</h5>
                </UseCaseItemHeader>
                <div>
                    <p>Tenson created a mini documentary series of their expedition to Mount Everest base camp. Using Qiwio, they were able to turn their incredible doc material into a seamless shopping experience.</p>
                    <a href="https://tenson.com/" target="_blank" rel="noreferrer">View on Tenson's site <HiOutlineArrowNarrowRight /></a>
                </div>
            </USeCaseItem>
            <USeCaseItem>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/dXY1Z9js_T0?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <UseCaseItemHeader>
                    <h5>AJ Produkter</h5>
                    <h5>Retail office equipment</h5>
                </UseCaseItemHeader>
                <div>
                    <p>AJ produkter create high quality imagery and videos for products and marketing campaigns. Now, by using Qiwio, they can make all of their content more engaging and shoppable with the aim raise engagement, time on site and conversions on their eCommerce platform.</p>
                    <a href="https://www.ajprodukter.se/kampanjer/personlig-service-ett-samtal-bort/tips-for-en-battre-kontorsmiljo" target="_blank" rel="noreferrer">View on AJ Produkter's site <HiOutlineArrowNarrowRight /></a>
                </div>
            </USeCaseItem>
        </UseCaseWrapper>
      </HomepageSection>
      {/*
      <HomepageSection>
        <h4>Use case</h4>
        <h3>See what's possible with interactive and shoppable video</h3>

        <UseCaseWrapper>
            <USeCaseItem>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/--g35zBy7iu0kSyApNnbBA?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <div>
                    <p>Add interactive hotspots to product videos to engage the viewer to discover more about the products they are interested in and nudge them towards a sale. Increase purchase intent by 900% and add to cart conversions by 19% with interactive hotspots.</p>
                </div>
            </USeCaseItem>
            <USeCaseItem>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/MHPsZ6M0QOfB94sHNI-4DQ?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <div>
                    <p>Create a truly shoppable experience by adding product sidebars to your videos. Just like in Instagram shopping, product sidebars will allow your viewers to browse the collection of products seen in the video. This level of video engagement dramatically increases purchase intent and conversions of your videos.</p>
                </div>
            </USeCaseItem>
        </UseCaseWrapper>
      </HomepageSection>
    */}
      <HomepageSection>
        <h4>Product</h4>
        <h3>Explore our interactive video platform</h3>
        <Tabs />
      </HomepageSection>

      <HomepageSection>
        <h4>Services</h4>
        <h3>
          Get more out of your Qiwio subscription and stand out from the crowd
        </h3>
        <ServicesContainer>
          <ServicesImgContainer>
            <StaticImage
              className="services-img"
              src="../images/homepage/InteractiveVideoGraphic2.png"
              alt="Interactive video"
              loading="eager"
              placeholder="none"
            />
          </ServicesImgContainer>

          <InfoWrapper>
            <p>
              Qiwio’s creative team will help your interactive videos stand out
              from the crowd. Our team can help you create world class
              interactive video experience your customers will love! We will
              also teach you expert tips and tricks on how to create an
              interactive video masterpiece.
            </p>
            <PrimaryBtn to="/contact-sales">Let's chat</PrimaryBtn>
          </InfoWrapper>
        </ServicesContainer>
      </HomepageSection>

      <HomepageSection>
        <CustomerCasesHeader>
          <h3>See what our customers have to say</h3>
        </CustomerCasesHeader>
        <TestimonialVideoWrapper>
          <TestimonialVideo>
            <QiwioVideo
              src="https://player.qiwio.io/dHBl51EII3E4kvCG5TErqQ?mode=embed"
              frameborder="0"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
              allowFullScreen
            ></QiwioVideo>
          </TestimonialVideo>
          <Quote>
            <StaticImage
              className="case-quote"
              src="../../../images/customers/tenson/chris.jpeg"
              alt="Logo"
              loading="eager"
              placeholder="none"
            />
            <h2>
              “When we look at the <span>future</span> of ecommerce, we think
              about <span>shoppable content</span>”
            </h2>
            <p>Chris Doyle</p>
            <p>Director of Marketing & Sustainability @ Tenson</p>
            <PrimaryBtn to="/case-studies/tenson">
              See full case study
            </PrimaryBtn>
          </Quote>
        </TestimonialVideoWrapper>
      </HomepageSection>

      <CtaSection />
      <NewsletterSection />
    </Layout>
  )
}

// Defining page styles
const QiwioPink = "#ff5679"
const BlackBg = "#1C1C26"

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 100px 30px;

  @media screen and (min-width: 900px) {
    margin-bottom: 100px;
  }
`
const NewFeatureTag = styled.h5`
  background-color: ${QiwioPink};
  padding: 5px 40px;
  border-radius: 50px;
  margin-bottom: 30px;
  transition: transform 0.4s;

  &:hover {
    transform: translateY(-4px);
  }

  @media screen and (max-width: 900px) {
    font-size: 0.875rem;
    padding: 5px 20px;
  }
`
const Heading1 = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 800;

  @media screen and (min-width: 900px) {
    max-width: 1000px;
    width: 90%;
  }
  @media screen and (min-width: 1200px) {
    font-size: 4.375rem;
    max-width: 1180px;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
  }

  span {
    text-decoration: line-through ${QiwioPink} double;
  }
`
const Heading4 = styled.h4`
  text-align: center;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 1.125rem;

  @media screen and (min-width: 900px) {
    font-size: 1.5rem;
  }
`
const BtnContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 70vw;

  @media screen and (min-width: 501px) {
    max-width: 500px;
    flex-direction: row;
    justify-content: center;
  }
`
const PrimaryBtn = styled(Link)`
  background-color: ${QiwioPink};
  color: white;
  border: 1px solid ${QiwioPink};
  border-radius: 4px;
  width: 100%;

  font-family: "Barlow Semi Condensed";
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  padding: 10px 20px;
  text-decoration: none;
  margin-bottom: 10px;

  transition: all 0.4s;

  &:hover {
    transform: translateY(-4px);
  }

  @media screen and (min-width: 501px) {
    width: 49%;
    margin-bottom: 0;
  }
`
const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HeroVideoWrapper = styled.video`
  max-width: 100%;
  border-radius: 10px;
  z-index: 1;

  @media screen and (min-width: 501px) {
    max-width: 60%;
  }
`
const HeroVideoContainer = styled.div`
  min-width: 60vw;
  border-radius: 10px;
  z-index: 1;

  @media screen and (min-width: 501px) {
    max-width: 60%;
  }
`
const CustomerLogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 0 0;
  z-index: 1;

  @media screen and (max-width: 500px) {
    padding: 50px 0 50px 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 50px;
    text-align: center;

    @media screen and (max-width: 500px) {
      width: 80%;
      font-size: 1.25rem;
    }
  }
`
const CustomerLogosContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`
const CustomerLogo = styled.div`
  text-align: center;

  @media screen and (max-width: 500px) {
    :not(:last-child) {
      margin-bottom: 30px;
    }
  }

  img {
    width: 150px;
    object-fit: contain;

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
`
const FeaturedInLogosWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 50px 0 0 0;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 50px;

    @media screen and (max-width: 650px) {
      width: 80%;
      font-size: 1.25rem;
      text-align: center;
      margin: 0 0 30px 0;
    }
  }
`
const FeaturedInLogo = styled.div`
  text-align: center;

  @media screen and (max-width: 500px) {
    :not(:last-child) {
      margin-bottom: 30px;
    }
  }

  img {
    width: 150px;
    object-fit: contain;

    @media screen and (max-width: 500px) {
      width: 70%;
      margin: 0 auto;
    }
  }
`
const CounterContainer = styled.div`
  margin: 50px 0 130px 0;
  padding: 0 30px;

  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    padding: 0 50px;
  }

  @media screen and (min-width: 1400px) {
    padding: 0 150px;
  }

  @media screen and (min-width: 2300px) {
    max-width: 60%;
    margin: 100px auto 0 auto;
  }
`
const HomepageSection = styled.section`
  //padding: 0 30px 100px 30px;
  padding: ${({ noPadding }) =>
    noPadding ? "0 0 100px 30px" : "0 30px 100px 30px"};

  h3 {
    font-size: 1.625rem;
    margin-bottom: 20px;

    @media screen and (min-width: 900px) {
      font-size: 2.5rem;
    }
  }

  h4 {
    font-size: 0.875rem;
    margin-bottom: 10px;
    font-family: "Menlo";

    @media screen and (min-width: 900px) {
      font-size: 1.125rem;
    }
  }

  p {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 900px) {
    padding: ${({ noPadding }) =>
      noPadding ? "0 0 100px 50px" : "0 50px 100px 50px"};
  }

  @media screen and (min-width: 2300px) {
    max-width: 70%;
    margin: 100px auto 0 auto;
  }
`
const CustomerCasesHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;

  h4 {
    width: 60%;
  }
  a {
    display: none !important;
    width: 40%;
    background-color: #ff5679;
    display: flex;
    font-family: "Barlow Semi Condensed";
    font-weight: 500;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    padding: 6px 0;
    transform: translateY(-8px);
    transition: all 0.4s;
    max-width: 200px;
    margin-left: auto;

    &:hover {
      transform: translateY(-12px);
    }
  }
  h3 {
    width: 100%;
    margin-top: 10px;
  }
`
const UseCaseSliderItemWrapper = styled.div`
  display: grid;
  border-radius: 10px;
  overflow: hidden;
`
const UseCaseSliderItemOverlay = styled.div`
  grid-area: 1/1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 30%,
    rgba(0, 0, 0, 0) 100%
  );

  h3 {
    font-size: 1.25rem;
    margin-bottom: 5px;
    font-weight: 600;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 20px;
  }
`
const TestimonialVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`
const TestimonialVideo = styled.div`
  width: 100%;
  border-radius: 10px;
  align-self: center;
  aspect-ratio: 16 / 9;

  @media screen and (min-width: 900px) {
    max-width: 50%;
  }
  @media screen and (min-width: 1300px) {
    max-width: 60%;
  }
`
const Quote = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px 100px 10px;

  @media screen and (min-width: 1000px) {
    max-width: 80vw;
    margin: 0 auto;
    padding: 100px 50px;
  }
  @media screen and (min-width: 1800px) {
    max-width: 1400px;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    font-weight: 400;
    font-family: "Barlow";
    margin-bottom: 10px;
  }

  span {
    color: #ff5679;
  }

  p {
    font-family: "Barlow Semi Condensed";
    text-align: center;
  }
  p:last-child {
    font-family: "Menlo";
    font-size: 0.875rem;
  }
`
const TestimonialCarouselSlideInfoWrapper = styled.div`
  padding: 20px;

  h3 {
    @media screen and (min-width: 600px) {
      font-size: 1.875rem;
    }
  }

  p {
    font-family: "Menlo";
    font-size: 0.75rem;
  }
`
const UseCaseHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    div:first-child {
      max-width: 50%;
    }
    div:last-child {
      max-width: 50%;
    }
  }
`
const UseCaseWrapper = styled.div`
  display: flex;
  margin: 50px 0 0 0;

  @media screen and (min-width: 901px) {
    overflow: scroll;
    --ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const USeCaseItem = styled.div`
  min-width: 500px;
  max-width: 600px;
  cursor: url("../images/scroll-cursor-icon-small.png"), auto;

  &:not(:last-child) {
    margin-right: 30px;
  }
  p {
    line-height: 1.6;
    font-size: 1rem;
  }
  a {
    color: white;

    svg {
      transform: translateY(3px);
    }
  }

  @media screen and (max-width: 900px) {
    min-width: 100%;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`
const SquareUSeCaseItem = styled.div`
  min-width: 500px;
  max-width: 600px;

  &:not(:last-child) {
    margin-right: 30px;
  }
  p {
    line-height: 1.6;
    font-size: 1rem;
  }
  a {
    color: white;

    svg {
      transform: translateY(3px);
    }
  }
  @media screen and (max-width: 900px) {
    min-width: 100%;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  @media screen and (min-width: 501px) and (max-width: 1700px) {
    min-width: 400px;
  }
`
const UseCaseItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 10px 0;

  h5 {
    font-size: 1rem;
    font-weight: 500;

    &:nth-child(2) {
      color: #80818b;
    }
  }
`
const QiwioVideoContainer = styled.div`
  aspect-ratio: 16 / 9;

  @supports not (aspect-ratio: 16 / 9) {
    width: 640px;
    height: 360px;

    @media screen and (max-width: 900px) {
      width: 100%;
      height: 100%;
    }
  }
`
const QiwioVideo = styled.iframe`
  border: none;
  min-width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 16 / 9;

  @media screen and (max-width: 900px) {
    min-height: 190px;
    aspect-ratio: 16 / 9;
    max-width: 90vw;
    border-radius: 5px;
  }
`
const SquareQiwioVideo = styled.iframe`
  border: none;
  width: 100%;
  max-height: 600px;
  max-width: 600px;
  aspect-ratio: 1 / 1;
  border-radius: 10px;

  @media screen and (max-width: 900px) {
    min-height: 220px;
  }
`
const ServicesContainer = styled.div`
  @media screen and (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 50px;
  }
`
const ServicesImgContainer = styled.div`
  @media screen and (min-width: 900px) {
    max-width: 45%;
    padding-right: 100px;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    max-width: 55%;
    padding-right: 50px;
  }

  a {
    @media screen and (min-width: 1000px) {
      max-width: 25%;
    }
  }
`
