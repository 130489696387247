import styled from "styled-components";
import { Link } from "gatsby";

const QiwioPink = '#ff5679'

export const PrimaryBtn = styled(Link)`
    background-color: ${QiwioPink};
    color: white;
    border: 1px solid ${QiwioPink};
    border-radius: 4px;
    width: 100%;

    font-family: 'Barlow Semi Condensed';
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    padding: 10px 20px;
    text-decoration: none;
    margin-bottom: 10px;

    transition: all .4s;

    &:hover {
        transform: translateY(-4px)
    }

    @media screen and (min-width: 501px) {
        width: 49%;
        margin-bottom: 0;
    }

    @media screen and (min-width: 900px) {
        width: 200px;
    }
`

export const CtaContainer = styled.section`
    padding: 100px 0 100px 30px;

    @media screen and (min-width: 900px) {
        padding: 200px 0 100px 30px;
    }

    h4 {
        font-family: 'Menlo';
        font-size: 1rem;
        margin-bottom: 10px;

        @media screen and (min-width: 900px) {
            font-size: 1.25rem;
        }
    }

    h2 {
        font-size: 3rem;
        margin-bottom: 30px;
        text-align: center;

        span {
            color: ${QiwioPink}
        }

        @media screen and (min-width: 900px) {
            font-size: 5rem;
        }
    }
`
export const CtaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        max-width: 50%;
    }
`
export const TestimonialContainer = styled.div`
    display: flex;
    overflow: scroll;
    --ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`
export const TestimonialCard = styled.div`
    margin-top: 50px;
    background-color: #272731;
    padding: 20px;
    border-radius: 6px;
    max-width: 60%;
    min-width: 60%;

    &:not(:first-child) {
        margin-left: 20px;
    }

    &:last-child {
        margin-right: 20px;
    }

    p {
        font-family: 'barlow';
        font-size: 1rem;
    }

    h6 {
        font-family: 'barlow';
        font-size: 1rem;
        font-weight: bold;
        margin-top: 10px;
    }

    @media screen and (min-width: 900px) {
        max-width: 300px;
        min-width: 300px;
    }
`