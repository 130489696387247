import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { NewsletterContainer } from './newsletter.styles'

export default function NewsletterSection() {
  return (
      <NewsletterContainer>
        <StaticImage
            className="newsletter-logo"
            src='../../images/logos/Q Logo.svg'
            alt='Qiwio logo'
            loading='eager'
            placeholder='none'
        />
        <h3>Stay up to date with the latest interactive video news & trends</h3>

        <form method='post' netlify-honeypot='bot-field' data-netlify='true' name='newsletter sign up form'>
          <input type="hidden" name='bot-field' />
          <input type="hidden" name='form-name' value='newsletter sign up form' />

          <input type="email" name="email" placeholder="Email address" />
          <button type="submit">Subscribe</button>
        </form>
      </NewsletterContainer>
  )
}