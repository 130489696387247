import React from 'react';

import {
    CtaContainer,
    CtaWrapper,
    TestimonialContainer,
    TestimonialCard,
    PrimaryBtn
} from './cta-section.styles'

export default function CtaSection() {
  return (
      <CtaContainer>
        <CtaWrapper>
          <h4>Don't just take our word for it</h4>
          <h2>Ready to try <span>Qiwio?</span></h2>
          <PrimaryBtn to='/contact-sales'>Talk to us</PrimaryBtn>
        </CtaWrapper>

        <TestimonialContainer>
          <TestimonialCard>
            <p>What a great idea! As a massive fan of “Choose your own adventure books” and visual novels, this appeals to me on so many levels. The devs have put a lot of thought into this app and it really shows.</p>
            <h6>Laurence</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>This tool is amazing if you plan to create interactive videos or for any type of video content related business. Looking forward to using this tool in our business on a daily basis.</p>
            <h6>Sunny</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>Qiwio is exactly what we were looking for! They have a fast loading and lightweight video player that doesn’t have a significant impact on our web core vitals. with Qiwio – performance was surprisingly refreshing!</p>
            <h6>Digital Shift</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>I have had some experience with other similar tools, but none of them had such a simple and logical user interface with incredible options as seen with Qiwio. The possibilities are endless!</p>
            <h6>Mary Zee</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>Once you have your content and marketing in place, Qiwio allows your customers to feel like they are in control. When every step of the sales journey becomes voluntary, sales are guaranteed to follow.</p>
            <h6>Daniel</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>I’ve only been using Qiwio for a couple of days, but I’m already in love with it! I was able to create and post a video within 2 hours and the rate of interaction is already three times what I would normally expect.</p>
            <h6>DB</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>Qiwio helps with better engagement and conversions for our video marketing efforts. The platform is simple and intuitive to use. And I love when founders pay serious attention to the design aspect of the product.</p>
            <h6>John K Qauntm</h6>
          </TestimonialCard>
          <TestimonialCard>
            <p>Qiwio is a brilliant interactive video solution. All the important features you need to create interactive video, without being over-complicated. And best of all: it also works smoothly for users in mainland China! Brilliant! </p>
            <h6>Shi Di Wen</h6>
          </TestimonialCard>
        </TestimonialContainer>
      </CtaContainer>
  )
}
