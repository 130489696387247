import styled from "styled-components";

const QiwioPink = '#ff5679'

export const NewsletterContainer = styled.section`
    padding: 0 30px 100px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 900px) {
        padding-bottom: 200px;
    }

    h3 {
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        max-width: 80%;

        @media screen and (min-width: 900px) {
            font-size: 1.625rem;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 90%;

        @media screen and (min-width: 900px) {
            flex-direction: row;
            justify-content: center;
        }
    }

    input {
        background-color: #272731;
        border: none;
        padding: 20px;
        border-radius: 4px;

        &::placeholder {
            font-size: 1rem;
            font-family: 'Barlow Semi Condensed';
            font-weight: 600;
            color: white;
            opacity: 0.5;
        }

        &:focus {
            outline: 1px solid #ff5679;
        }

        @media screen and (min-width: 900px) {
            min-width: 30%;
            margin-right: 10px;
        }
    }

    button {
        background-color: ${QiwioPink};
        border: 1px solid ${QiwioPink};
        color: white;
        margin-top: 10px;
        padding: 12px;
        border-radius: 4px;
        font-size: 1rem;
        font-family: 'Barlow Semi Condensed';
        font-weight: 600;
        color: white;
        cursor: pointer;
        transition: all .4s;

        &:hover {
            transform: translateY(-4px)
        }

        @media screen and (min-width: 900px) {
            min-width: 200px;
            margin-top: 0;
        }
    }
`