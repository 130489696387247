import React from "react";

import {
    TabContainer,
    VideoContainer,
    TextContainer,
    QiwioVideo
} from './tabs.styles'

const SecondTab = () => {
    return(
        <TabContainer>
            <VideoContainer>
                <QiwioVideo src="https://player.qiwio.io/aD2Y6mD0XheG3AVvV2HPxg?mode=embed&auto=true" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                {/*
                <TabVideo autoPlay muted loop>
                    <source src="https://res.cloudinary.com/qiwio/video/upload/q_auto,f_auto,so_1/v1654163917/Funnels-feature.mp4" type="video/mp4"/>
                </TabVideo>
                */}
            </VideoContainer>
            <TextContainer>
                <p>Allow your viewers to take control and personalize their viewing experience by adding choices to your video and allow your viewer to choose their own journey. Simply add hotspots, images or buttons and link them to keyframes or other videos entirely. Giving your viewers control to personalize their experience creates a more engaging experience which results in higher conversions.</p>
            </TextContainer>
        </TabContainer>
    )
}

export default SecondTab